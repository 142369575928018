




























































import { mapState } from 'vuex';
import router from '@/router';

interface helpMaterialsItem {
  text: string;
  icon?: string;
  component?: any;
}
interface quickLinksItem {
  text: string;
  link: string;
}

const CogIcon = (): any =>
  import('@/assets/images/icons/cog-4-line.svg?inline');

export default {
  name: 'CopyStakeGuidanceView',
  data: (): any => ({
    isLoading: false as boolean
  }),
  computed: {
    ...mapState('FeatureFlags', ['features', 'loading']),
    ...mapState('app', ['isSuperAdmin']),
    isEnabledCopyStake(): boolean {
      return (
        this.features.find((feature) => feature.name === 'live_streamer')
          ?.enabled === true
      );
    },
    helpMaterialsList(): helpMaterialsItem[] {
      return [
        {
          text: 'Configure the feature',
          component: CogIcon
        },
        {
          text: 'Promote the feature among casino audience',
          icon: 'mdi-account-group-outline'
        },
        {
          text: 'Provide support to users',
          icon: 'mdi-email-outline'
        }
      ];
    },
    quickLinksList(): quickLinksItem[] {
      return [
        {
          text: 'Feature Launch',
          link: 'https://cdn.trueplay.io/CopyStake_Feature_Launch_Guide.pdf'
        },
        {
          text: 'Placement',
          link: 'https://cdn.trueplay.io/CopyStake_Guide_Feature_Placement.pdf'
        },
        {
          text: 'Streaming Setting',
          link: 'https://cdn.trueplay.io/CopyStake_Guide_Streaming_Settings.pdf'
        },
        {
          text: 'OBS Settings',
          link: 'https://cdn.trueplay.io/CopyStake_Guide_OBS_studio_settings.pdf'
        },
        {
          text: 'Interface',
          link: 'https://cdn.trueplay.io/CopyStake_Web_Interface.pdf'
        },
        {
          text: 'Admin',
          link: 'https://cdn.trueplay.io/CopyStake_Guide_Operator_Admin.pdf'
        },
        {
          text: 'FAQ',
          link: 'https://cdn.trueplay.io/CopyStake_Guide_FAQ.pdf'
        },
        {
          text: 'Recommended Games',
          link: 'https://cdn.trueplay.io/CopyStake_Recommended_Games.pdf'
        }
      ];
    }
  },
  watch: {
    loading: {
      handler(value: boolean): void {
        if (value || this.isEnabledCopyStake || this.isSuperAdmin) return;

        router.push('/');
      },
      immediate: true
    }
  },
  methods: {
    openLink(item: quickLinksItem): void {
      window.open(item.link, '_blank');
    }
  }
};
